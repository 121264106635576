@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Heebo:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Heebo', sans-serif;
}

textarea:focus, input:focus {
  outline: none;
}

input[type='checkbox'] {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

:root {
  font-size: 62.5%; /* 1rem = 10px */
}

body {
  display: flex;
  justify-content: center;
}